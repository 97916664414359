body {
  margin: 0;
  padding: 0;
  min-width: 350px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #ffffff;
  color: #000;
  border: 2px solid gray;
  border-radius: 5px;
  display: inline-block;
  position: relative;
}

.react-datepicker-popper {
  z-index: 100000000 !important;    
}